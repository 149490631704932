import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

Vue.config.productionTip = false;
import localstore from "store";

import { infos } from "./datasets/peterpan.js";

new Vue({
  router,
  render: (h) => h(App),
  data: {
    modal_is_open: false,
    revealed_balises: [],
    openable_balises: [],
    opened_balises: [],
    gps_locations: [],
    balise_in_range: undefined,
    is_narrow_screen: false,

    infos: infos,
    publicPath: process.env.BASE_URL,

    show_welcome_modal: false,
    mode: "",
  },
  watch: {
    gps_locations() {
      localstore.set("gps_locations", this.gps_locations);
    },
    revealed_balises() {
      localstore.set("revealed_balises", this.revealed_balises);
    },
    openable_balises() {
      localstore.set("openable_balises", this.openable_balises);
    },
    opened_balises() {
      localstore.set("opened_balises", this.opened_balises);
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);

    this.gps_locations = localstore.get("gps_locations")
      ? localstore.get("gps_locations")
      : [];

    if (localstore.get("revealed_balises")) {
      if (localstore.get("revealed_balises").length > 0)
        this.revealed_balises = localstore.get("revealed_balises");
    }
    if (this.revealed_balises.length === 0) this.show_welcome_modal = true;

    this.openable_balises = localstore.get("openable_balises")
      ? localstore.get("openable_balises")
      : [];
    this.opened_balises = localstore.get("opened_balises")
      ? localstore.get("opened_balises")
      : [];
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.is_narrow_screen = window.innerWidth < 780;
    },
  },
}).$mount("#app");
