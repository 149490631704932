export const infos = {
  main_title: `Peter Pan à Malakoff`,
  title: `La bal(l)ade du Jolly&nbsp;Rodger`,
  desc: `Et si les chapitres de Peter Pan devenaient des notes de musique ? Et si les
  quartiers de Malakoff se transformaient en village des pirates, en rues de
  Londres, en Pays du Jamais ou en traversée du Jolly Rodger ? L’application
  Balades Sonores vous fera découvrir autrement cette ville que vous croyez
  connaître.`,
};

export const parcours = [
  {
    infos: {
      title: "Dans les rues de Londres",
      desc: `C’est ici que tout commence. Que ce soit dans les jardins de Kensington où Peter
    est né, ou dans la maison des Darling, l’histoire de Peter et Wendy n’existerait pas
    sans Londres. C’est parti&nbsp;!`,
      color: "#00B8AF",
      preview: `parcours1.png`,
    },
    route: [
      [0],
      [48.81935460037787, 2.301297783851624],
      [48.81921331530048, 2.301217317581177],
      [48.81920271890362, 2.299720644950867],
      [48.81912501192488, 2.299795746803284],
      [48.818842440077596, 2.3003911972045903],
      [48.81813600349019, 2.2998064756393437],
      [48.81806535928385, 2.2999674081802373],
      [48.81808302034476, 2.3000371456146245],
      [48.81802297271228, 2.3001605272293095],
      [48.817818103777725, 2.299919128417969],
      [48.81777924923016, 2.2999888658523564],
      [1],
      [48.817740394652475, 2.2999620437622075],
      [48.81706573309298, 2.3013299703598027],
      [48.8170304102481, 2.301297783851624],
      [48.816744294287226, 2.3019039630889897],
      [48.817623830738704, 2.3028802871704106],
      [48.817567314804165, 2.3030304908752446],
      [48.81811481023874, 2.3032879829406743],
      [48.818157196732656, 2.3032504320144658],
      [48.81828788819662, 2.3025637865066533],
      [48.81870821789209, 2.3027783632278447],
      [48.81844330462917, 2.3041516542434697],
      [2],
      [48.81777884570869, 2.3038827436367675],
      [48.817713906353156, 2.30483104772826],
      [3],
      [48.81764502419775, 2.305787801742554],
      [48.81711871731365, 2.305439114570618],
      [48.817493137543394, 2.3037546873092656],
      [48.81726000829494, 2.3036527633666997],
      [48.81626743072348, 2.305696606636048],
      [48.81623210731609, 2.305819988250733],
      [48.81770507228299, 2.3078799247741704],
      [48.818181922170915, 2.3074293136596684],
      [4],
    ],
    balises: [
      {
        chapter: "I",
        name: "Les Jardins de Kensington",
        subname: "Square",
        text: `Ici, une fée joue au milieu des fleurs. Là, une autre saupoudre de sucre glace un
        beignet plus gros qu’elle. Ignorant les enfants qui jouent avec leurs cerceaux, le
        peuple des fées se prépare à faire la fête. Voilà qu’arrive Peter Pan qui se met à
        jouer de la flûte. Et les fées le suivent, dans une joyeuse ribambelle.`,
        coords: [48.81935460037787, 2.301297783851624],
        audio_file: `11.mp3`,
      },
      {
        chapter: "II",
        name: "Au 14 de la rue",
        subname: "",
        text: `Comment imaginez-vous la maison de Wendy, John et Michael&nbsp;? En briques&nbsp;? En
        pierres grises&nbsp;? De deux ou trois étages&nbsp;? Comment est son jardin&nbsp;? Est-ce qu’une
        bonne odeur de rôti flotte dans l’air&nbsp;? Est-ce qu’une fenêtre ouverte laisse
        s’échapper quelques notes jouées au piano par Mme Darling&nbsp;? Nana vous
        accueille-t-elle en aboyant&nbsp;? Bienvenue au 14 de la rue.`,
        coords: [48.817740394652475, 2.2999620437622075],
        audio_file: `12.mp3`,
      },
      {
        chapter: "III",
        name: "Une maison pleine d’amour",
        subname: "",
        text: `Ostensiblement posé au coin des lèvres de Mme Darling, à droite, un baiser que
      Wendy ne parvient jamais à prendre la nargue. Oh, comme elle aime son mari et
      ses enfants, Mme Darling&nbsp;! Et ce bon M. Darling, même s’il se fâche contre Nana,
      aime profondément chaque membre de sa famille. Écoutez les rire aux éclats&nbsp;!
      C’est une maison pleine d’amour.`,
        coords: [48.81844330462917, 2.3041516542434697],
        audio_file: `13.mp3`,
      },
      {
        chapter: "IV",
        name: "Wendy raconte l’histoire de Cendrillon",
        subname: " ",
        text: `John et Michael sont assis à même le sol. Wendy, posée sur le bord de son lit,
      raconte l’histoire de cette fille abandonnée, oubliée au milieu des cendres, qui par
      l’amour du Prince devient une princesse. Les yeux de John scintillent comme des diamants quand Wendy raconte la scène de l’essayage de la pantoufle de verre. Et
      sous les toits, les hirondelles écoutent l’histoire, elles aussi.`,
        coords: [48.817713906353156, 2.30483104772826],
        audio_file: `14.mp3`,
      },
      {
        chapter: "V",
        name: "Nana est enfermée dans sa niche",
        subname: "La maison des arts",
        text: `C’est l’odeur qui l’alerte. Un parfum, de feuilles, de résine qui suinte des arbres. Le
      garçon qui avait perdu son ombre&nbsp;! Les enfants sont en danger. Nana tire de
      toutes ses forces, sans parvenir à détacher la corde qui lui interdit de s’éloigner
      de sa niche. Elle aboie en vain. «&nbsp;Bon voyage&nbsp;», pense-t-elle, résignée, quand les
      enfants volent vers le Pays du Jamais.`,
        coords: [48.818181922170915, 2.3074293136596684],
        audio_file: `15.mp3`,
      },
    ],
  },
  {
    infos: {
      title: `Le Pays du Jamais`,
      desc: `Les rêves de chaque enfant façonnent le Pays de Jamais. On y trouve des sirènes,
      des Indiens, des maisons dans les arbres, des fauves, des pirates et d’étranges
      créatures. Et bien sûr, des fées.`,
      color: "#AFA5FF",
      preview: `parcours2.png`,
    },
    route: [
      [0],
      [48.81830201698314, 2.292205095291138],
      [48.81772626570764, 2.2912180423736577],
      [48.81775452359329, 2.291089296340943],
      [48.816889118619684, 2.2890293598175053],
      [48.8164758383205, 2.2879511117935185],
      [48.81637693312825, 2.2878974676132207],
      [48.81580822448587, 2.288487553596497],
      [48.81575523888009, 2.2884714603424077],
      [48.81567046179435, 2.288541197776795],
      [1],
      [48.81505582363505, 2.2890937328338627],
      [48.81450476232686, 2.287865281105042],
      [48.814423515595976, 2.2879242897033696],
      [48.81437052852663, 2.287935018539429],
      [48.813946629955794, 2.2889703512191777],
      [48.81369582161368, 2.28966236114502],
      [48.81360044346413, 2.289989590644837],
      [48.813059963856006, 2.2914165258407597],
      [48.81299637763668, 2.2916740179061894],
      [48.81291159588547, 2.2919905185699467],
      [48.81287273753493, 2.2921192646026616],
      [48.81284800947798, 2.2920173406600957],
      [48.81296105192443, 2.291604280471802],
      [48.81288686784771, 2.2915506362915044],
      [48.81225453245251, 2.2933423519134526],
      [48.8122156735927, 2.293460369110108],
      [2],
      [48.81226513031815, 2.2935730218887334],
      [48.812258065074644, 2.293809056282044],
      [48.81213795578273, 2.2939753532409672],
      [48.81216974944682, 2.294130921363831],
      [48.81325778713369, 2.296582460403443],
      [48.81354745552486, 2.296228408813477],
      [48.81408439738446, 2.2956383228302006],
      [48.81414091724566, 2.2954988479614262],
      [48.81496044807287, 2.2945386171340947],
      [48.816341609798, 2.292929291725159],
      [48.81657474331762, 2.292585968971253],
      [48.81690324780043, 2.293235063552857],
      [3],
      [48.81699861966643, 2.293428182601929],
      [48.81674782659301, 2.2937393188476567],
      [48.816924441564105, 2.2940933704376225],
      [48.81661359879882, 2.294560074806214],
      [48.816528823164624, 2.2946190834045415],
      [48.81616146042661, 2.29515016078949],
      [48.81606961932147, 2.295257449150086],
      [48.81600603691935, 2.2953861951828007],
      [48.81591066316494, 2.2954934835433964],
      [48.81476969640415, 2.296700477600098],
      [48.81470611235327, 2.2968345880508427],
      [48.81552210154928, 2.2984600067138676],
      [48.81488979939129, 2.2992324829101567],
      [48.814918058875854, 2.299350500106812],
      [4],
    ],
    balises: [
      {
        chapter: "I",
        name: "Les mésaventures de Lys Tigré",
        subname: "Gare de Vanves-Malakoff",
        text: `Lys Tigré aiguise son couteau et attrape son arc. Elle se prépare à chasser. Chaque
        jour, elle doit prouver qu’elle n’est pas moins féroce, pas moins redoutable qu’un
        homme. Elle piste les traces d’un immense cerf. Elle ne voit que trop tard les
        mains qui s’abattent sur elle, la chargent de cordes et la bâillonnent. «&nbsp;Le
        Capitaine Crochet sera ravi&nbsp;», fanfaronne un pirate.`,
        coords: [48.81830201698314, 2.292205095291138],
        audio_file: `21.mp3`,
      },
      {
        chapter: "II",
        name: "L’Oiseau blanc perd son nid",
        subname: "Le jardin partagé des Nouzeaux",
        text: `À regret, l’Oiseau blanc regarde Peter voguer dans la lagune, sur son nid. Sa bonté
        le perdra&nbsp;! Où vivra sa famille, désormais&nbsp;? «&nbsp;Hibou à tête de linotte&nbsp;!&nbsp;» se flagelle-
        t-il. Il avise un chapeau à larges bords qui traîne sur le rivage. Ma foi, il ne semble
        pas si mal&nbsp;! Il est même superbe. Les autres oiseaux seront jaloux. Au bout de cinq
        minutes, il se demande comment il a pu vivre dans un autre nid.`,
        coords: [48.81567046179435, 2.288541197776795],
        audio_file: `22.mp3`,
      },
      {
        chapter: "III",
        name: "Rock and roll chez les sirènes",
        subname: "Rond point",
        text: `Un, deux, trois et quatre, cinq et six&nbsp;! Pas de base, espagnole, on enroule et
        déplacement&nbsp;! Dépité, Peter fixe les sirènes se déhancher n’importe comment.
        Elles pourraient faire un effort&nbsp;! L’absence de jambe n’est pas une excuse. La plus
        grande lui lance une bulle multicolore. Peter boude. Quant aux sirènes, elles rient,
        elles rient et s’amusent en dansant à leur façon un énergique rock and roll.`,
        coords: [48.8122156735927, 2.293460369110108],
        audio_file: `23.mp3`,
      },
      {
        chapter: "IV",
        name: "Construisons une maison pour Wendy",
        subname: "",
        text: `Autour de Wendy allongée, Peter Pan prend soigneusement les mesures. En
        moins de temps qu’il n’en faut pour le dire, le peuple des fées et les enfants
        perdus élèvent autour d’elle une charmante maison, avec une belle cheminée, de
        mignonnes fenêtres, un berceau pour y déposer un bébé, et un soulier comme
        heurtoir sur la porte. «&nbsp;L&#39;exquise, la délicieuse petite maison&nbsp;!&nbsp;» s&#39;exclame Wendy.`,
        coords: [48.81690324780043, 2.293235063552857],
        audio_file: `24.mp3`,
      },
      {
        chapter: "V",
        name: "Clochette boude",
        subname: "Le Malaqueen",
        text: `Ah, les hommes&nbsp;! Peter ne comprend jamais rien. Furieuse, Clochette ouvre le
        rideau de son boudoir et s’y réfugie. Sa colère donne à la lumière une teinte
        orange. Elle s’assied à sa table de toilette de chez Chippendale, se mire dans le
        miroir. Elle se trouve laide. Pleurant de rage, elle s’allonge sur son Reine Mab aux
        pieds rococo et inonde de larmes sa courtepointe. Ah, les hommes&nbsp;!`,
        coords: [48.814918058875854, 2.299350500106812],
        audio_file: `25.mp3`,
      },
    ],
  },
  {
    infos: {
      title: `Village des pirates`,
      desc: `Les pirates ne passent pas toute leur vie sur mer. Parfois, ils se délassent au sol,
      dépensent leur or et soufflent, en espérant que les corsaires de la Compagnie des
      Indes n’aient toujours pas trouvé leur village secret.`,
      color: "#A25AA5",
      preview: `parcours3.png`,
    },
    route: [
      [0],
      [48.821297229808465, 2.3011636734008794],
      [48.82161157549336, 2.3019146919250493],
      [48.821911791441735, 2.3017108440399174],
      [48.822604047245115, 2.3033040761947636],
      [48.82344816255699, 2.302821278572083],
      [48.82402262156386, 2.3043379296006883],
      [1],
      [48.82299255396626, 2.304972410202027],
      [48.82215902675485, 2.3057341575622563],
      [48.821756385763564, 2.3049831390380864],
      [48.82153740421732, 2.305294275283814],
      [48.82191885532476, 2.3060560226440434],
      [2],
      [48.82187294006735, 2.306361794471741],
      [48.82203187731757, 2.306667566299439],
      [48.8227241314623, 2.30789065361023],
      [48.82225085670338, 2.308362722396851],
      [48.822978426501884, 2.3095536231994633],
      [48.82246983513245, 2.3102295398712163],
      [48.82222966519174, 2.3098218441009526],
      [48.82216962252673, 2.309837937355042],
      [48.821251313981584, 2.311055660247803],
      [48.821067650253525, 2.31063187122345],
      [3],
      [48.82073599490776, 2.3099974084187163],
      [48.82183408866286, 2.3088026046752934],
      [48.82068266063959, 2.3064851760864262],
      [48.821223058067254, 2.3059487342834477],
      [48.82054844338179, 2.3052513599395756],
      [48.82077096127111, 2.3050314188003544],
      [48.82049899482779, 2.303947806358338],
      [48.81996212167277, 2.3018878698349003],
      [48.8199515254342, 2.3016786575317387],
      [4],
    ],
    balises: [
      {
        chapter: "I",
        name: "Marché aux pirates",
        subname: "Marché ouvert",
        text: `Approchez, approchez&nbsp;! Pour une pièce d’or, vous pourrez trouver ici les épices
        les plus rares, en provenance du lointain orient. Si vous êtes désargenté, vous
        pourrez troquer une bouteille de rhum contre des ananas, des goyaves ou du
        corossol. Si vous êtes le possesseur d’une carte égarée de la Compagnie des Indes,
        on vous couvrira d’or, à moins que l’on ne vous poignarde pour vous la dérober.`,
        coords: [48.821297229808465, 2.3011636734008794],
        audio_file: `31.mp3`,
      },
      {
        chapter: "II",
        name: "La fête des pirates",
        subname: "Le Timbre poste",
        text: `Un vieux pirate pose sa jambe de bois sur une table et sort son bandonéon. Les
        querelles s’effacent, les rires éclatent. Là, on sort une bouteille de rhum. Ici,
        quelques matelots jouent aux cartes. Le second esquisse des pas de danse. Sorti
        de nulle part, un violoneux joue une gigue. A voir cette bande faire la fête, on ne
        croirait pas que ce sont de féroces pirates.`,
        coords: [48.82402262156386, 2.3043379296006883],
        audio_file: `32.mp3`,
      },
      {
        chapter: "III",
        name: "Une nuit pas si paisible que ça",
        subname: "",
        text: `Allongé dans un hamac, seul sur la plage, le vieux marin contemple le ciel. Il aime
        ces nuits de garde solitaires. Il sort un vieux livre de poèmes, vestige d’une
        époque où sa tête n’était pas mise à prix. La constellation du lion est superbe, ce
        soir. Au loin, un grondement attire son attention. Des lueurs vives éclatent. « Ça
        sent les ennuis&nbsp;», chuchote le marin avant de courir vers le tocsin.`,
        coords: [48.82187294006735, 2.306361794471741],
        audio_file: `33.mp3`,
      },
      {
        chapter: "IV",
        name: "Chant de pirate",
        subname: "Usine Clacquesin",
        text: `Larguez les ris, yo ho hisse ho ! Nous allons piratant ! Et si un coup de feu nous
        sépare, Nous sommes sûrs de nous trouver réunis en enfer.
        Yo ho, yo ho, la fortune sourit aux audacieux. Pillez, percez des bedaines de Lords
        et délestez ces dames de leurs bijoux.
        Le pavillon à tête de mort ! La belle vie, une corde de chanvre ! Et l&#39;on va boire la
        grande tasse !`,
        coords: [48.821067650253525, 2.31063187122345],
        audio_file: `34.mp3`,
      },
      {
        chapter: "V",
        name: "Tic-tac le crocodile",
        subname: "La Tréso",
        text: `Tic-tac. Au plus fort de la fête, un simple bruit sème le trouble. Dès qu’il l’entend,
        le vaillant capitaine Crochet s’enferme dans son manoir. Son équipage le suit
        courageusement. Moins d’une minute plus tard, un immense crocodile arrive. Il
        ouvre grand la gueule et le tic-tac résonne plus fortement. A-t-il un nom, ce
        messager du temps qui passe&nbsp;? Tic-tac le crocodile.`,
        coords: [48.81999744245177, 2.3016411066055302],
        audio_file: `35.mp3`,
      },
    ],
  },
  {
    infos: {
      title: `La Traversée du Jolly Rodger`,
      desc: `Il est temps de quitter le Pays du Jamais. Quoi de mieux qu’un bateau pirate pour
      effectuer la traversée jusqu’à Londres&nbsp;? Histoire de découvrir sous un autre angle
      ces lieux enchanteurs avant de les quitter.`,
      color: "#6498DE",
      preview: `parcours4.png`,
    },
    route: [
      [0],
      [48.813194201165274, 2.2920763492584233],
      [48.81294692163257, 2.2926235198974614],
      [1],
      [48.812992845066574, 2.293058037757874],
      [48.815059356059784, 2.297601699829102],
      [48.81560334649976, 2.2970652580261235],
      [48.81567399417578, 2.297033071517945],
      [48.815783497876815, 2.2972154617309575],
      [48.81644051505995, 2.296378612518311],
      [48.81627449540197, 2.296577095985413],
      [2],
      [48.81656061404425, 2.2963196039199834],
      [48.81660300185237, 2.2964215278625493],
      [48.816730165061664, 2.296555638313294],
      [48.8160519575512, 2.2975909709930424],
      [48.8160236987058, 2.297703623771668],
      [48.81606961932147, 2.297805547714234],
      [48.81601310163465, 2.29790210723877],
      [3],
      [48.81702334567614, 2.2994416952133183],
      [48.81688467334043, 2.299710205634176],
      [48.816529997200945, 2.3001881508963034],
      [48.8180835600227, 2.301948203290092],
      [48.81787875224525, 2.3023806299558074],

      [48.81912257195388, 2.302957198843441],
      [48.82019523835433, 2.3027354478836064],
      // [48.81912257195388, 2.302957198843441],
      [48.82078155733643, 2.3050260543823247],
      [48.82055903949413, 2.3052513599395756],
      [48.821706938401256, 2.3064047098159794],
      [48.82203187731757, 2.3066514730453496],
      [48.82192945114742, 2.3060292005538945],
      [48.8221519629057, 2.3057073354721074],
      [48.82238860131073, 2.305465936660767],
      [48.822748854647784, 2.30614185333252],
      [4],
    ],
    balises: [
      {
        chapter: "I",
        name: "L’Arbre des enfants perdus",
        subname: "Parc Salagnac",
        text: `La quille du bateau frôle la cime des arbres. Il semble qu’on n’ait qu’à tendre le
        bras pour les toucher. Un, deux, trois, quatre, cinq, six, sept. Chacun son entrée
        pour rejoindre la maison souterraine. Une main s’agite avec nostalgie pour faire
        ses adieux à ces lieux qui ont abrité tant de rires et d’aventures. Un tintement
        argentin se fait entendre. Clochette vous salue.`,
        coords: [48.813194201165274, 2.2920763492584233],
        audio_file: `41.mp3`,
      },
      {
        chapter: "II",
        name: "Le Lagon des sirènes",
        subname: "Étang du Parc Salagnac",
        text: `Une voix s’élève du Lagon. Cristalline, elle transperce l’âme et quelques larmes
        sont versées. Un chœur la rejoint. Les sirènes se lamentent, faisant monter leurs
        chants vers la lune qui va bientôt disparaître. Plusieurs jouent une mélodie
        lugubre avec des conques. Elles n’ont pas le cœur à s’amuser. Vivement la
        prochaine pleine lune&nbsp;!`,
        coords: [48.81294692163257, 2.2926235198974614],
        audio_file: `42.mp3`,
      },
      {
        chapter: "III",
        name: "Le village des Indiens",
        subname: "",
        text: `Les tipis ont presque la même couleur que le sol. Bien malin qui saurait les
        distinguer, même de haut. Un grand feu est allumé, à côté de l’immense totem aux
        sculptures patiemment façonnées, génération après génération, chamane après
        chamane. Les tambours résonnent et l’on chante paisiblement. Aujourd’hui est un
        soir de fête. Lys Tigré est revenue saine et sauve.`,
        coords: [48.81627449540197, 2.296577095985413],
        audio_file: `43.mp3`,
      },
      {
        chapter: "IV",
        name: "Voyage dans les étoiles",
        subname: "",
        text: `Le lourd bateau s’arrache enfin. Délaissant le Pays du Jamais, il se dirige vers les
        étoiles. La proue pointe vers Alpha du Centaure. Le pavillon claque au vent. Il fait
        froid. Certains rentrent dans leur cabine. Au moins, en plein ciel, aucune tempête
        n’est à prévoir&nbsp;! Le Jolly Rodger ne tangue pas. Se penchant au-dessus du
        bastingage, un marin contemple l’ancre qui pend, inutile.`,
        coords: [48.82019523835433, 2.3027354478836064],
        audio_file: `44.mp3`,
      },
      {
        chapter: "V",
        name: "Arrivée chez les Darling",
        subname: "Sentier du Tir",
        text: `Attention, il ne faut pas arracher une cheminée&nbsp;! Posons le Jolly Rodger dans les
        Jardins de Kensington et finissons à pied. Au 14 de la rue, la veilleuse de la
        chambre des enfants scintille. Mme Darling joue tristement du piano, M. Darling
        dort dans la niche. Chut&nbsp;! Pas un bruit&nbsp;! Faisons-leur la surprise jusqu’à la dernière
        seconde. Voici venue l’heure des retrouvailles.`,
        coords: [48.822748854647784, 2.30614185333252],
        audio_file: `45.mp3`,
      },
    ],
  },
];
